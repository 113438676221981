"use client";

import { SupportedLocales } from "@xenia-libs/resident-app-i18n/supported-locales";
import { Button } from "@xenia-libs/xenia-ui/button";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@xenia-libs/xenia-ui/form-control";
import { Radio, RadioGroup } from "@xenia-libs/xenia-ui/radio";
import { visuallyHidden } from "@xenia-libs/xenia-ui/utils";
import { useState, useTransition } from "react";
import { useFormStatus } from "react-dom";

import { ChooseLanguageProps } from "../types/choose-language-props";

export type ChooseLanguageInputsProps = Pick<
  ChooseLanguageProps,
  "options" | "defaultLocale" | "onLanguageSelect"
> & {
  /**
   * All possible messages for the choose language page. We shouldn't pass all messages
   * as this is a client component, so all messages would cross the server/client boundary.
   */
  messages: Record<
    SupportedLocales,
    IntlMessages["features"]["choose_language"]
  >;
};

export function ChooseLanguageInputs({
  options,
  messages,
  defaultLocale,
  onLanguageSelect,
}: ChooseLanguageInputsProps) {
  const formStatus = useFormStatus();
  const [, startTransition] = useTransition();
  const [locale, setLocale] = useState(defaultLocale);

  return (
    <>
      <FormControl sx={{ overflow: "auto" }}>
        <FormLabel style={visuallyHidden}>
          {messages[locale].form.label}
        </FormLabel>
        <RadioGroup
          name="locale"
          value={locale}
          data-testid="choose-language"
          onChange={(e) => {
            const locale = e.target.value as SupportedLocales;

            setLocale(locale);
            startTransition(() => {
              onLanguageSelect?.(locale);
            });
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              data-testid={`choose-language-${option.value}`}
              control={
                <Radio
                  inputProps={{
                    // This will get passed to the DOM
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    "data-testid": option.value,
                  }}
                />
              }
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <Button
        type="submit"
        sx={{ alignSelf: "end" }}
        loading={formStatus.pending}
        data-testid="choose-language-submit"
      >
        {messages[locale].form.continue}
      </Button>
    </>
  );
}
